import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'; // Importando o Navigate
import Home from './Components/Home';
import QuadroObreiroPage from './Components/QuadroObreiroPage';
import IniciacaoPage from './Components/IniciacaoPage';
import AniversarioPage from './Components/AniversarioPage';
import MemoriasFotograficasPage from './Components/MemoriasFotograficasPage';
import IframePage from './Components/IframePage';
import Login from './Components/Login'; // Componente de Login

const App = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const storedUser = sessionStorage.getItem('user'); // Altere para sessionStorage
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const PrivateRoute = ({ element }) => {
    return user ? element : <Navigate to="/login" />;
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<PrivateRoute element={<Home user={user} />} />} />
        <Route path="/login" element={<Login setUser={setUser} />} />
        <Route path="/quadro-obreiro" element={<QuadroObreiroPage />} />
        <Route path="/iniciacao" element={<IniciacaoPage />} />
        <Route path="/aniversario" element={<AniversarioPage />} />
        <Route path="/memorias-fotograficas" element={<MemoriasFotograficasPage />} />
        <Route path="/gestao-anterior" element={<IframePage src="https://estrelacaldense.com.br/novo_app/GestoesAnteriores.html" />} />
        <Route path="/nominata" element={<IframePage src="https://estrelacaldense.com.br/novo_app/nominata.html" />} />
        <Route path="/regimento-interno" element={<IframePage src="https://estrelacaldense.com.br/novo_app/baixarpdf.html" />} />
        <Route path="/corpos" element={<IframePage src="https://estrelacaldense.com.br/novo_app/corposfilosoficos.html?v=1234" />} />
        <Route path="/fundadores" element={<IframePage src="https://estrelacaldense.com.br/novo_app/fundadores.html?v=1234" />} />
        <Route path="/veneraveis-mestres" element={<IframePage src="https://estrelacaldense.com.br/novo_app/veneraveis.html?v=1234" />} />
        <Route path="/nossas-escolas" element={<IframePage src="https://estrelacaldense.com.br/novo_app/escolas.html?v=1234" />} />
        <Route path="/nossa-creche" element={<IframePage src="https://estrelacaldense.com.br/novo_app/creches.html?v=1234" />} />
        <Route path="/entidades" element={<IframePage src="https://estrelacaldense.com.br/novo_app/arcoiris.html?v=1234" />} />
        <Route path="/creditos" element={<IframePage src="https://estrelacaldense.com.br/novo_app/creditos.html?v=1234" />} />
      </Routes>
    </Router>
  );
};

export default App;
