import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Select,
  Text,
  Flex,
  IconButton,
  Icon,
  Center,
  Image,
  Divider
} from '@chakra-ui/react';
import { FaCalendarAlt, FaArrowLeft, FaUser, FaPeopleArrows, FaBirthdayCake } from 'react-icons/fa';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AniversarioPage = () => {
  const [membros, setMembros] = useState([]);
  const [familia, setFamilia] = useState([]);
  const [mesSelecionado, setMesSelecionado] = useState(new Date().getMonth() + 1); // Mês atual
  const navigate = useNavigate();

  useEffect(() => {
    // Consumir o endpoint getMembros
    axios.get('https://estrelacaldense.com.br:3636/getMembros?id_pessoa=0&id_insituicao=0')
      .then(response => {
        console.log('Membros recebidos:', response.data.result[0]); // Adicionar console log para verificar os membros
        setMembros(response.data.result[0]);
      })
      .catch(error => {
        console.error('Erro ao buscar membros:', error);
      });

    // Consumir o endpoint getFamiliaPessoa
    axios.get('https://estrelacaldense.com.br:3636/getFamiliaPessoa?id_pessoa=0')
      .then(response => {
        console.log('Família recebida:', response.data.result[0]); // Adicionar console log para verificar a família
        setFamilia(response.data.result[0]);
      })
      .catch(error => {
        console.error('Erro ao buscar família:', error);
      });
  }, []);

  const handleHomeClick = () => {
    navigate('/');
  };

  const handleMesChange = (e) => {
    setMesSelecionado(Number(e.target.value));
  };

  const padronizarDataNascimento = (item) => {
    // Padronizar a data de nascimento
    const data = new Date(item.data_nascimento || item.nascimento);
    return data.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit' });
  };

  const filtrarAniversariantesPorMes = (aniversariantes) => {
    const filtrados = aniversariantes.filter(item => {
      const dataNascimento = padronizarDataNascimento(item);
      const [dia, mes] = dataNascimento.split('/').map(Number);
      return mes === mesSelecionado;
    });
    console.log(`Aniversariantes filtrados para o mês ${mesSelecionado}:`, filtrados); // Console log para verificar os filtrados
    return filtrados;
  };

  const membrosFiltrados = filtrarAniversariantesPorMes(membros);
  const familiaFiltrada = filtrarAniversariantesPorMes(familia);

  // Função para agrupar aniversariantes por data e ordenar
  const agruparPorData = (lista) => {
    return lista.reduce((acc, item) => {
      const dataNascimento = padronizarDataNascimento(item);
      if (!acc[dataNascimento]) {
        acc[dataNascimento] = [];
      }
      acc[dataNascimento].push(item);
      return acc;
    }, {});
  };

  const aniversariantesAgrupados = agruparPorData([...membrosFiltrados, ...familiaFiltrada]);

  // Ordenar as chaves por data
  const ordenarPorData = (aniversariantesAgrupados) => {
    const datasOrdenadas = Object.keys(aniversariantesAgrupados).sort((a, b) => {
      const [diaA, mesA] = a.split('/').map(Number);
      const [diaB, mesB] = b.split('/').map(Number);
      return new Date(2024, mesA - 1, diaA) - new Date(2024, mesB - 1, diaB);
    });
    return datasOrdenadas.reduce((acc, data) => {
      acc[data] = aniversariantesAgrupados[data];
      return acc;
    }, {});
  };

  const aniversariantesOrdenados = ordenarPorData(aniversariantesAgrupados);

  // Função para determinar a descrição do vínculo de um membro ou familiar
  const obterDescricao = (item, membros) => {
    const membroAssociado = membros.find(m => m.id_pessoa === item.id_pessoa);
    if (item.id_familia) {
      // Vem da família
      return <div dangerouslySetInnerHTML={{ __html: `${item.nome} <br> ${item.tipo} ${membroAssociado ? `do Ir∴ ${membroAssociado.nome}` : ''}` }} />;
    } else {
      // Vem dos membros
      return `Ir∴ ${item.nome}`;
    }
  };

  return (
    <Box
      bg="hsl(192.86, 63.64%, 82.75%)"
      p={4}
      borderWidth={1}
      borderRadius="md"
      boxShadow="md"
      minHeight="100vh"
      position="relative"
    >
      <Box
        position="absolute"
        top={4}
        left={4}
        zIndex={10}
      >
        <IconButton
          icon={<FaArrowLeft />}
          aria-label="Go Back"
          onClick={handleHomeClick}
          size="lg"
          colorScheme="blackAlpha" // Ajusta para uma variação de preto
          variant="solid"
          bg="black" // Fundo preto
          color="white" // Ícone branco para contraste
          borderRadius="full"
          boxShadow="0 4px 8px rgba(0, 0, 0, 0.2)"
          _hover={{ bg: "gray.800" }} // Cor mais escura no hover
        />

      </Box>
      <Center mb={4}>
        <Box textAlign="center" mb={4}>
          <Image
            src="/icones/logo.png"
            alt="Logo"
            boxSize="128px"
            objectFit="contain"
            m="auto"
          />
        </Box>
      </Center>
      <Center mb={4}>
        <Box textAlign="center" mb={4}>
          <Icon as={FaCalendarAlt} boxSize={8} color="black" />
          <Select
            value={mesSelecionado}
            onChange={handleMesChange}
            width="200px"
            borderColor="gray.300"
            _hover={{ borderColor: 'blue.500' }}
            _focus={{ borderColor: 'blue.500', boxShadow: '0 0 0 1px blue.500' }}
            mt={2}
          >
            {['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'].map((mes, index) => (
              <option key={index} value={index + 1}>{mes}</option>
            ))}
          </Select>
        </Box>
      </Center>

      <Container>
        {/* Exibir aniversariantes agrupados e ordenados */}
        {Object.keys(aniversariantesOrdenados).map((data, index) => (
          <Box
            key={data}
            mb={6}
            p={4}
            borderRadius="md"
            bg="hsl(192.86, 63.64%, 72%)" // Azul mais forte
            boxShadow="md"
          >
            <Text fontSize="xl" fontWeight="bold" mb={2}>{data}</Text>
            {aniversariantesOrdenados[data].map((item, i, array) => (
              <Box key={item.id_pessoa || item.id_familia}>
                <Text mb={1}>
                  <Icon as={item.id_familia ? FaBirthdayCake : FaBirthdayCake} mr={2} />
                  {obterDescricao(item, membros)}
                </Text>
                {i < array.length - 1 && <Divider borderColor="gray.300" mb={2} />}
              </Box>
            ))}
          </Box>
        ))}
      </Container>
    </Box>
  );
};

export default AniversarioPage;
