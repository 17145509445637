import React from 'react';
import { Box, IconButton } from '@chakra-ui/react';
import { FaArrowLeft } from 'react-icons/fa'; // Importa o ícone da seta
import { useNavigate } from 'react-router-dom';

const IframePage = ({ src }) => {
  const navigate = useNavigate();

  const handleHomeClick = () => {
    navigate('/');
  };

  return (
    <Box
      p={4}
      bg="hsl(192.86, 63.64%, 82.75%)"
      minHeight="100vh"
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      overflow="auto" // Permite rolagem se necessário
    >
      {/* Back Button */}
      <Box
        position="absolute"
        top={4}
        left={4} // Ajustado para que o botão fique no canto superior esquerdo
        zIndex={10} // Garantir que o botão fique sobre o iframe
      >
        <IconButton
          icon={<FaArrowLeft />}
          aria-label="Go Back"
          onClick={handleHomeClick}
          size="lg"
          colorScheme="blackAlpha" // Ajusta para uma variação de preto
          variant="solid"
          bg="black" // Fundo preto
          color="white" // Ícone branco para contraste
          borderRadius="full"
          boxShadow="0 4px 8px rgba(0, 0, 0, 0.2)"
          _hover={{ bg: "gray.800" }} // Cor mais escura no hover
        />

      </Box>

      {/* Iframe */}
      <Box
        as="iframe"
        src={src}
        width="100vw" // Ajusta a largura do iframe para 100% da largura da viewport
        height="100vh" // Ajusta a altura do iframe para 100% da altura da viewport
        border="none" // Remove a borda do iframe
        bg="white"
        boxShadow="none" // Remove a sombra do iframe
        position="absolute"
        top="50px"
        left="0"
        overflow="hidden" // Garante que não haja barras internas de rolagem
      />
    </Box>
  );
};

export default IframePage;
