import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Input,
  Stack,
  Image,
  Text,
  VStack,
  HStack,
  Divider,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Center,
  SimpleGrid,
  Button,
  Flex,
  useToast,
} from '@chakra-ui/react';
import { FaEye, FaArrowLeft, FaUser, FaEnvelope, FaWhatsapp, FaMapMarkerAlt, FaMapPin, FaIndustry, FaBriefcase, FaPhone, FaIdCard } from 'react-icons/fa'; // Ícone de voltar e ver mais
import { useNavigate } from 'react-router-dom';

const QuadroDeObreio = () => {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [familiaData, setFamiliaData] = useState([]);
  const [profissaoData, setProfissaoData] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://estrelacaldense.com.br:3636/getMembros?id_pessoa=0&id_insituicao=0');
        setData(response.data.result[0]);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    setFilteredData(
      data.filter((item) =>
        item.nome.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, data]);
  const navigate = useNavigate();
  const handleItemClick = async (item) => {
    setSelectedItem(item);
    await fetchFamiliaData(item.id_pessoa);
    await fetchProfissaoData(item.id_pessoa);
    onOpen();
  };
  const handleHomeClick = () => {
    navigate('/');
  };

  const fetchFamiliaData = async (idPessoa) => {
    try {
      const response = await axios.get(`https://estrelacaldense.com.br:3636/getFamiliaPessoa?id_pessoa=${idPessoa}`);
      setFamiliaData(response.data.result[0]);
    } catch (error) {
      toast({
        title: 'Erro ao buscar dados da família.',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const fetchProfissaoData = async (idPessoa) => {
    try {
      const response = await axios.get(`https://estrelacaldense.com.br:3636/getProfissaoPessoa?id_pessoa=${idPessoa}`);
      setProfissaoData(response.data.result[0]);
    } catch (error) {
      toast({
        title: 'Erro ao buscar dados de profissão.',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      bg="hsl(192.86, 63.64%, 82.75%)"
      p={4}
      borderWidth={1}
      borderRadius="md"
      boxShadow="md"
      minHeight="100vh"
    >
      <Box
        position="absolute"
        top={4}
        left={4} // Ajustado para que o botão fique no canto superior esquerdo
        zIndex={10} // Garantir que o botão fique sobre o iframe
      >
        <IconButton
          icon={<FaArrowLeft />}
          aria-label="Go Back"
          onClick={handleHomeClick}
          size="lg"
          colorScheme="blackAlpha" // Ajusta para uma variação de preto
          variant="solid"
          bg="black" // Fundo preto
          color="white" // Ícone branco para contraste
          borderRadius="full"
          boxShadow="0 4px 8px rgba(0, 0, 0, 0.2)"
          _hover={{ bg: "gray.800" }} // Cor mais escura no hover
        />

      </Box>
      <Center mb={4}>
        <Box textAlign="center" mb={4}>
          <Image
            src="/icones/logo.png"
            alt="Logo"
            boxSize="128px"
            objectFit="contain"
            m="auto"
          />
        </Box>
      </Center>

      <Center mb={4}>
        <Input
          placeholder="Buscar por nome"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          mb={4}
          width="300px"
          borderRadius="full"
          bg="white"
          boxShadow="md"
        />
      </Center>

      {filteredData.length === 0 ? (
        <Text textAlign="center">Nenhum responsável encontrado.</Text>
      ) : (
        <Stack spacing={4}>
          {filteredData.map((responsavel) => (
            <Box
              key={responsavel.id_pessoa}
              p={4}
              bg="#93C8E6"
              borderRadius="lg"
              boxShadow="lg"
              cursor="pointer"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              onClick={() => handleItemClick(responsavel)}
            >
              <HStack spacing={4}>
                <Image
                  src={`https://estrelacaldense.com.br:3636/Fotos/${responsavel.id_pessoa}.png`}
                  alt={responsavel.nome}
                  boxSize="60px"
                  borderRadius="none"
                  objectFit="contain"
                />
                <VStack align="start" spacing={1}>
                  <Text fontWeight="bold">{responsavel.nome}</Text>
                </VStack>
              </HStack>
              <IconButton
                aria-label="Ver Mais"
                icon={<FaEye size="24px" />}
                onClick={() => handleItemClick(responsavel)}
                variant="solid"
                colorScheme="teal"
                size="lg"
                borderRadius="full"
              />
            </Box>
          ))}
        </Stack>
      )}

      {/* Modal para exibir detalhes */}
      {selectedItem && (
        <Modal isOpen={isOpen} onClose={onClose} size="full">
          <ModalOverlay />
          <ModalContent bg="hsl(192.86, 63.64%, 82.75%)" borderRadius="lg" p={0} m={0} maxH="100vh" minH="100vh">
            <ModalHeader textAlign="center" p={4}>Detalhes</ModalHeader>
            <ModalCloseButton />
            <ModalBody maxHeight="calc(100vh - 150px)" overflowY="auto" p={4} bg="white" >
              <VStack spacing={6} align="center" w="100%">
                {/* Foto do Responsável */}
                <Center>
                  <Image
                    src={`https://estrelacaldense.com.br:3636/Fotos/${selectedItem.id_pessoa}.png`}
                    alt={selectedItem.nome}
                    boxSize="90px"
                    borderRadius="none"
                    objectFit="contain"
                    mb={4}
                  />
                </Center>

                {/* Informações Pessoais */}
                <SimpleGrid columns={2} spacing={4} bg="hsl(192.86, 63.64%, 92.75%)" w="100%">
                  <Box>
                    <Flex align="center">
                      <FaUser style={{ marginRight: '8px' }} />
                      <Text fontWeight="bold">Nome:</Text>
                    </Flex>
                    <Text mb={2}>{selectedItem.nome}</Text>
                  </Box>
                  <Box>
                    <Flex align="center">
                      <FaIdCard style={{ marginRight: '8px' }} />
                      <Text fontWeight="bold">CPF:</Text>
                    </Flex>
                    <Text mb={2}>{selectedItem.cpf}</Text>
                  </Box>
                  <Box>
                    <Flex align="center">
                      <FaPhone style={{ marginRight: '8px' }} />
                      <Text fontWeight="bold">Telefone:</Text>
                    </Flex>
                    <Text mb={2}>{selectedItem.fone}</Text>
                  </Box>
                  <Box>
                    <Flex align="center">
                      <FaMapMarkerAlt style={{ marginRight: '8px' }} />
                      <Text fontWeight="bold">Endereço:</Text>
                    </Flex>
                    <Text mb={2}>{selectedItem.endereco}</Text>
                  </Box>
                </SimpleGrid>

                <Divider />

                {/* Seção de Profissão com Fundo Azul */}
                {profissaoData.length > 0 && (
                  <Box w="100%" bg="hsl(192.86, 63.64%, 92.75%)" p={4} borderRadius="md">
                    <Text fontSize="lg" fontWeight="bold" mb={2}>
                      Profissão
                    </Text>
                    <SimpleGrid columns={2} spacing={4}>
                      <Box>
                        <Flex align="center">
                          <FaBriefcase style={{ marginRight: '8px' }} />
                          <Text fontWeight="bold">Profissão:</Text>
                        </Flex>
                        <Text mb={2}>{profissaoData[0].profissao}</Text>
                      </Box>
                      <Box>
                        <Flex align="center">
                          <FaIndustry style={{ marginRight: '8px' }} />
                          <Text fontWeight="bold">Ramo:</Text>
                        </Flex>
                        <Text mb={2}>{profissaoData[0].ramo}</Text>
                      </Box>
                      <Box>
                        <Flex align="center">
                          <FaMapPin style={{ marginRight: '8px' }} />
                          <Text fontWeight="bold">Local:</Text>
                        </Flex>
                        <Text mb={2}>{profissaoData[0].local}</Text>
                      </Box>
                      <Box>
                        <Flex align="center">
                          <FaMapMarkerAlt style={{ marginRight: '8px' }} />
                          <Text fontWeight="bold">Endereço:</Text>
                        </Flex>
                        <Text mb={2}>
                          {profissaoData[0].endereco}, {profissaoData[0].numero} {profissaoData[0].complemento}
                        </Text>
                      </Box>
                      <Box>
                        <Flex align="center">
                          <FaWhatsapp style={{ marginRight: '8px' }} />
                          <Text fontWeight="bold">WhatsApp:</Text>
                        </Flex>
                        <Text mb={2}>{profissaoData[0].whatsapp}</Text>
                      </Box>
                      <Box>
                        <Flex align="center">
                          <FaPhone style={{ marginRight: '8px' }} />
                          <Text fontWeight="bold">Fone:</Text>
                        </Flex>
                        <Text mb={2}>{profissaoData[0].fone}</Text>
                      </Box>
                      <Box>
                        <Flex align="center">
                          <FaEnvelope style={{ marginRight: '8px' }} />
                          <Text fontWeight="bold">Email:</Text>
                        </Flex>
                        <Text mb={2}>{profissaoData[0].email}</Text>
                      </Box>
                    </SimpleGrid>
                  </Box>
                )}

                {/* Seção de Família com Fundo Azul */}
                {familiaData.length > 0 && (
                  <Box w="100%" bg="hsl(192.86, 63.64%, 92.75%)" p={4} borderRadius="md">
                    <Text fontSize="lg" fontWeight="bold" mb={2}>
                      Familiares
                    </Text>
                    <SimpleGrid columns={2} spacing={4}>
                      {familiaData.map((familiar, index) => (
                        <Box key={index}>
                          <Flex align="center">
                            <FaUser style={{ marginRight: '8px' }} />
                            <Text fontWeight="bold">Nome:</Text>
                          </Flex>
                          <Text mb={2}>{familiar.nome}</Text>
                          <Flex align="center">
                            <FaUser style={{ marginRight: '8px' }} />
                            <Text fontWeight="bold">Parentesco:</Text>
                          </Flex>
                          <Text mb={2}>{familiar.tipo}</Text>
                        </Box>
                      ))}
                    </SimpleGrid>
                  </Box>
                )}
              </VStack>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
};

export default QuadroDeObreio;
