import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  SimpleGrid,
  Select,
  Image,
  Text,
  Flex,
  IconButton,
  Icon,
  Center,
} from '@chakra-ui/react';
import { FaCalendarAlt, FaUser, FaArrowLeft } from 'react-icons/fa';
import { HiPhotograph } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

const IniciacaoPage = () => {
  const [membros, setMembros] = useState([]);
  const [mesSelecionado, setMesSelecionado] = useState(new Date().getMonth() + 1); // Mês atual
  const meses = [
    'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
  ];
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch data from the API
    fetch('https://estrelacaldense.com.br:3636/getMembros?id_pessoa=0&id_insituicao=0')
      .then(response => response.json())
      .then(data => {
        const sortedMembros = data.result[0].sort((a, b) => {
          const dateA = new Date(a.data_iniciacao);
          const dateB = new Date(b.data_iniciacao);
          return dateB - dateA; // Inverter a ordem para mostrar os mais recentes primeiro
        });
        setMembros(sortedMembros);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const handleHomeClick = () => {
    navigate('/');
  };

  const handleMesChange = (e) => {
    setMesSelecionado(Number(e.target.value));
  };

  const filtrarMembrosPorMes = (mes) => {
    return membros.filter(membro => {
      const dataIniciacao = new Date(membro.data_iniciacao);
      return dataIniciacao.getMonth() + 1 === mes;
    });
  };

  const membrosFiltrados = filtrarMembrosPorMes(mesSelecionado);

  return (
    <Box
      bg="hsl(192.86, 63.64%, 82.75%)"
      p={4}
      borderWidth={1}
      borderRadius="md"
      boxShadow="md"
      minHeight="100vh"
      position="relative" // Garantir que o botão seja posicionado corretamente
    >
      <Box
        position="absolute"
        top={4}
        left={4} // Ajustado para que o botão fique no canto superior esquerdo
        zIndex={10} // Garantir que o botão fique sobre o iframe
      >
       <IconButton
  icon={<FaArrowLeft />}
  aria-label="Go Back"
  onClick={handleHomeClick}
  size="lg"
  colorScheme="blackAlpha" // Ajusta para uma variação de preto
  variant="solid"
  bg="black" // Fundo preto
  color="white" // Ícone branco para contraste
  borderRadius="full"
  boxShadow="0 4px 8px rgba(0, 0, 0, 0.2)"
  _hover={{ bg: "gray.800" }} // Cor mais escura no hover
/>

      </Box>
      <Center mb={4}>
        <Box textAlign="center" mb={4}>
          <Image
            src="/icones/logo.png"
            alt="Logo"
            boxSize="128px"
            objectFit="contain"
            m="auto"
          />
        </Box>
      </Center>

      {/* Seletor de Mês */}
      <Flex align="center" mb={6} flexDirection="column">
        <Icon as={FaCalendarAlt} boxSize={6} mb={2} color="black" />
        <Select
          value={mesSelecionado}
          onChange={handleMesChange}
          width="200px"
          borderColor="gray.300"
          _hover={{ borderColor: 'blue.500' }}
          _focus={{ borderColor: 'blue.500', boxShadow: '0 0 0 1px blue.500' }}
        >
          {meses.map((mes, index) => (
            <option key={index} value={index + 1}>{mes}</option>
          ))}
        </Select>
      </Flex>

      {/* Grid de Membros */}
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
        {membrosFiltrados.map((membro) => (
          <Box
            key={membro.id_pessoa}
            bg="hsl(192.86, 63.64%, 92.75%)"
            p={4}
            borderRadius="md"
            textAlign="center"
            boxShadow="md"
          >
            <Center mb={2}>
              <Box
                borderRadius="none"
                overflow="hidden"
                boxSize="90px"
                bg="gray.200"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Image
                  src={`https://estrelacaldense.com.br:3636/Fotos/${membro.foto}.png`}
                  alt={membro.nome}
                  boxSize="90px"
                  borderRadius="none"
                  objectFit="contain"
                />
              </Box>
            </Center>
            <Flex align="center" justify="center" mb={2}>
              <Icon as={FaUser} mr={2} />
              <Text fontWeight="bold">{membro.nome}</Text>
            </Flex>
            <Text>
              <Icon as={HiPhotograph} mr={2} />
              {new Date(membro.data_iniciacao).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' })}
            </Text>

          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default IniciacaoPage;
