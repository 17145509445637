import React, { useEffect, useState } from 'react';
import { Box, Grid, Image, Text, IconButton } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { FaSignOutAlt } from 'react-icons/fa'; // Importa o ícone de logout

const MotionBox = motion(Box);

const Home = () => {
  const [telas, setTelas] = useState([]);
  const [user, setUser] = useState(null); // Estado para armazenar o usuário
  const navigate = useNavigate(); // Hook para navegação

  useEffect(() => {
    const storedUser = sessionStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    } else {
      navigate('/login'); // Redirecionar para login se não houver usuário
    }

    fetch('https://estrelacaldense.com.br:3636/getTelasInstituicao?id_instituicao=1')
      .then((response) => response.json())
      .then((data) => setTelas(data.result[0] || []))
      .catch((error) => console.error('Error fetching data:', error));
  }, [navigate]);

  const handleLogout = () => {
    sessionStorage.removeItem('user'); // Remove o usuário do sessionStorage
    setUser(null); // Atualiza o estado do usuário
    navigate('/login'); // Redireciona para a página de login
  };

  const getPagePath = (titulo) => {
    switch (titulo) {
      case 'Gestões Anteriores':
        return '/gestao-anterior';
      case 'Quadro de Obreiros':
        return '/quadro-obreiro';
      case 'Nominata':
        return '/nominata';
      case 'Regimento Interno':
        return '/regimento-interno';
      case 'Corpos Filosóficos':
        return '/corpos';
      case 'Datas de Iniciação':
        return '/iniciacao';
      case 'Aniversários':
        return '/aniversario';
      case 'Fundadores':
        return '/fundadores';
      case 'Veneráveis Mestres':
        return '/veneraveis-mestres';
      case 'Nossas Escolas':
        return '/nossas-escolas';
      case 'Nossa Creche':
        return '/nossa-creche';
      case 'Entidades Paramaçonicas':
        return '/entidades';
      case 'Memórias Fotográficas':
        return '/memorias-fotograficas';
      case 'Creditos':
        return '/creditos';
      default:
        return '/gestao-anterior';
    }
  };

  const handleClick = (titulo) => {
    navigate(getPagePath(titulo));
  };

  return (
    <Box p={4} bg="hsl(192.86, 63.64%, 82.75%)">
      <Box textAlign="center" mb={4}>
        <Image
          src="/icones/logo.png"
          alt="Logo"
          boxSize="128px"
          objectFit="contain"
          m="auto"
        />
        {user && (
          <Box mt={4} bgColor="#93C8E6" p={4} borderRadius="md" textAlign="center">
            <Text fontSize="lg" fontWeight="bold" mt={2}>
              Seja Bem-vindo
            </Text>
            <Image
              src={`https://estrelacaldense.com.br:3636/Fotos/${user.id_pessoa}.png`} // URL da foto
              alt={user.nome}
              boxSize="80px"
              borderRadius="none"
              m="auto"
              mt={2}
            />
            <Text fontSize="lg" fontWeight="bold" mt={2}>
              Ir∴<br />{user.nome}!
            </Text>
          </Box>
        )}

      </Box>
      <Grid
        templateColumns="repeat(3, 1fr)"
        gap={4}
        autoFlow="dense"
      >
        {telas.map((tela) => (
          <MotionBox
            key={tela.id_tela}
            p={2}
            textAlign="center"
            whileHover={{ scale: 1.1 }}
            transition="all 0.3s ease"
            animate={{ scale: [1, 1.05, 1] }}
          >
            <Box
              p={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
              bg="#93C8E6"
              borderRadius="md"
              boxSize="60px"
              m="auto"
              boxShadow="0px 6px 12px rgba(0, 0, 0, 0.15), 0px 3px 6px rgba(0, 0, 0, 0.1)"
              transform="translateZ(0)"
              cursor="pointer"
              onClick={() => handleClick(tela.titulo)}
            >
              <Image
                src={`/icones/${tela.icone}`}
                alt={tela.titulo}
                boxSize="50px"
                objectFit="contain"
              />
            </Box>
            <Text fontSize="sm" fontWeight="medium" color="gray.700">{tela.titulo}</Text>
          </MotionBox>
        ))}
      </Grid>
    </Box>
  );
};

export default Home;
